<template>
  <div :class="mobileAndKinto ? 'login-mobile' : null">
    <form
      :class="form.class"
      @submit.prevent="onSubmit"
    >
      <p v-if="form.welcomeMessage" :class="form.welcomeMessage.class">
        {{ form.welcomeMessage.text || $t('login.enter_new_password') }}
      </p>
      <div class="col">
        <q-input
          v-model="email"
          :dark="mobileAndKinto ? null : form.email.dark"
          :filled="form.email.filled || null"
          square
          :color="mobileAndKinto ? 'black' : form.email.color"
          bottom-slots
          :class="form.email.class"
          type="email"
          :label="$t('email_address')"
          :error-message="$t('login.validation.email')"
          :hint="$t('validation.email')"
          :error="invalid('email')"
          @input="$v.email.$touch"
        />

        <q-input
          v-model="password"
          :dark="mobileAndKinto ? null : form.password.dark"
          :filled="form.password.filled || null"
          square
          :color="mobileAndKinto ? 'black' : form.password.color"
          bottom-slots
          type="password"
          :label="$t('new_password')"
          :error-message="$t('login.validation.password_invalid')"
          :hint="$t('login.validation.password_reset')"
          :error="invalid('password')"
          :class="form.password.class"
          @input="$v.password.$touch"
        />

        <q-input
          v-model="password_confirmation"
          type="password"
          :label="$t('login.retype_new_password')"
          :error="invalid('password_confirmation')"
          :error-message="$t('login.validation.passwords_do_not_match')"
          :hint="$t('login.validation.passwords_must_match')"
          :dark="mobileAndKinto ? null : form.passwordConfirmation.dark"
          :filled="form.passwordConfirmation.filled"
          square
          :color="mobileAndKinto ? null : form.passwordConfirmation.color"
          bottom-slots
          :class="form.passwordConfirmation.class"
          @input="$v.password_confirmation.$touch"
        />

        <q-btn
          type="submit"
          :disable="$v.$invalid"
          :flat="form.resetButton.flat || null"
          :size="form.resetButton.size || null"
          :class="form.resetButton.class || null"
          :align="form.resetButton.align || null"
          :icon-right="form.resetButton.iconRight || null"
          :color="form.resetButton.color || null"
        >
          {{ $t('submit') }}
        </q-btn>
      </div>
    </form>
  </div>
</template>

<script type="text/javascript">
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import getPartnerFromHostname from 'utils/partner'
const partner = getPartnerFromHostname()

export default {
  data () {
    return {
      email: '',
      password: '',
      password_confirmation: '',
      partnerName: partner.name,
      form: partner.pages.password.reset.form,
      mobile: window.innerWidth < 640
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    mobileAndKinto () {
      return this.mobile
    }
  },
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(8) },
    password_confirmation: { required, sameAsPassword: sameAs('password') }
  },
  methods: {
    invalid (field) {
      return this.$v[field].$invalid && this.$v[field].$dirty
    },
    onSubmit () {
      this.$emit('submit', {
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
button
  width 100%
  margin-top 30px

.message
  color #009640
  margin-top 30px
@import '../../../../styles/login.styl'
</style>

<template>
  <div style="width: 100%">
    <m-password-reset-form v-if="!done" @submit="onSubmit" />

    <div v-if="done" :class="partner.pages.password.reset.form.class">
      <p :class="partner.pages.password.reset.message">
        {{ $t('password_changed') }}
      </p>
      <router-link to="/login" :class="partner.pages.password.reset.link">
        {{ $t('click_to_log_in') }}
      </router-link>
    </div>

    <div v-if="error" :class="partner.pages.password.reset.form.class">
      <p
        v-for="(text, i) in $t('login.password_reset_expired')"
        :key="i"
        :class="partner.pages.password.reset.message"
      >
        {{ text }}
      </p>
      <router-link to="/password/email" :class="partner.pages.password.reset.link">
        {{ $t('request_new_password') }}
      </router-link>
    </div>
  </div>
</template>

<script type="text/javascript">
import { reset } from 'api/password'
import MPasswordResetForm from './MPasswordResetForm'
import { mapGetters } from 'vuex'

export default {
  components: {
    MPasswordResetForm
  },
  data () {
    return {
      token: this.$route.params.token,
      done: false,
      error: null
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    })
  },
  methods: {
    async onSubmit ({ email, password, password_confirmation }) {
      try {
        await reset({
          email,
          password,
          password_confirmation,
          token: this.$route.params.token
        })
        this.done = true
      } catch (err) {
        this.error = err.data.title
        this.done = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '../../../styles/login.styl'
</style>
